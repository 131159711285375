export function getParent(data: any, id: string, scope: string, type?: boolean) {
  let arrRes: any[] = []
  if (data.length === 0) {
    if (id) {
      arrRes.unshift(data)
    }
    return arrRes
  }
  const rev = (value: any, nodeId: string) => {
    for (let i = 0, length = value.length; i < length; i++) {
      const node = value[i]
      if (node.id === nodeId && node.id !== scope) {
        if (type) {
          if (nodeId !== scope) {
            // 去掉scope
            arrRes.unshift(node)
          }
        } else {
          if (nodeId !== scope && nodeId !== id) {
            // 去掉scope和本身
            arrRes.unshift(node)
          }
        }
        rev(data, node.parentId)
        break
      } else {
        if (node.children) {
          rev(node.children, nodeId)
        }
      }
    }
    return arrRes
  }
  arrRes = rev(data, id)
  return arrRes
}
