
























































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import AccessInfo from './AccessInfo.vue'
import PersonVisit from '../VisitorSearch/compenents/personDetail/personVisit.vue'
import { verification } from '@/utils/options'

@Component({ components: { FormDrawer, AccessInfo, PersonVisit } })
export default class DetailDrawer extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean
  @Prop({ default: () => ({}) }) data: AnyObj

  loading = false
  trace: AnyObj | null = null
  detail: AnyObj | null = null

  get root() {
    return this.$store.state.config?.root
  }

  get scope() {
    return this.root ? this.root.id : this.$store.state.user.scope
  }

  get personId() {
    return this.accessInfo?.id
  }

  get areas() {
    return this.$store.state.baseAreas
  }

  get processStatuses() {
    return verification.processStatuses
  }

  get verification() {
    return this.data
  }

  get approved() {
    return this.verification.result && this.verification.reported
  }

  get needProcess() {
    return (
      this.verification.reported &&
      (!this.verification.processStatus || this.verification.processStatus === 'undecided')
    )
  }

  get report() {
    return this.detail?.customerReporting
  }

  get accessInfo() {
    return this.detail?.accessInfo
  }

  get areaPath() {
    const areas: AnyObj[] = this.verification.areas ?? []

    return areas.map(i => i.name).join('/')
  }

  async mounted() {
    if (this.approved) {
      this.loading = true

      await this.fetchDetail()
      await this.fetchTraces()

      this.loading = false
    }
  }

  async fetchDetail() {
    const { data } = await this.$api.base.getVerification(this.data.id)

    if (data.code === 0) {
      this.detail = data.data
    }
  }

  async fetchTraces() {
    if (this.accessInfo) {
      const { id } = this.accessInfo
      const { data } = await this.$api.v2.getPersonTraceApi(id, {
        scopeId: this.scope,
        order: ':asc',
        limit: 1
      })

      if (data.code === 0 && data.data?.length > 0) {
        const [trace] = data.data
        this.trace = trace
      }
    }
  }

  formatDate(time: string) {
    return this.$moment(time).format('YYYY.MM.DD HH:mm:ss')
  }

  async updateProcessStatus(status: string) {
    this.loading = true
    const { data } = await this.$api.base.updateVerification(this.data.id, {
      processStatus: status
    })
    this.loading = false

    if (data.code === 0) {
      this.$emit('update')
      this.close()
    }
  }

  close() {
    this.syncedVisible = false
    this.detail = null
    this.trace = null
  }
}
