






import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  name: 'videoPlay',
  components: {}
})
export default class VideoPlay extends Vue {
  @Prop({}) private src: string
}
