














import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import ReportsTable from '../ClientAppointment/ReportsTable.vue'

@Component({ components: { FormDrawer, ReportsTable } })
export default class MatchReportDrawer extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean
  @Prop({ default: () => ({}) }) data: AnyObj

  loading = false

  async matchReport(row: any) {
    this.loading = true
    const { data } = await this.$api.base.matchVerificationReport(this.data.id, row.id)
    this.loading = false

    if (data.code === 0) {
      this.syncedVisible = false
      this.$emit('update')
    }
  }
  close() {
    this.syncedVisible = false
  }
}
