














































import { Vue, Component, Prop } from 'vue-property-decorator'
import VideoPlay from '@/components/common/Play/video.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'

@Component({
  components: {
    VideoPlay,
    FeatureImg
  }
})
export default class PersonSearch extends Vue {
  @Prop({ required: false, default: false }) showDetail: boolean
  @Prop({ required: false, default: '' }) title: string

  private showFlag = false
  private videoSrc = null
  private activeId = 0
  content: any[] = []
  loading = false

  deactivated() {
    this.showFlag = false
    this.content = []
    this.videoSrc = null
    this.loading = false
  }

  mounted() {
    this.showFlag = this.showDetail
    this.loading = true
  }
  videoClick(val: AnyObj, index: number) {
    this.videoSrc = val.src
    this.activeId = index
  }
  open(content: any) {
    this.content = content
    if (content.length > 0) this.videoSrc = content[0].src
    this.loading = false
  }
}
