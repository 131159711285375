










































































import { Vue, Component, Prop } from 'vue-property-decorator'
import CopyText from '@/components/common/CopyText.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { parseGender } from '@/utils'
import moment from 'moment'

@Component({ components: { CopyText, SecurityText, FeatureImg } })
export default class VisitingRecord extends Vue {
  @Prop({ required: true }) person: AnyObj

  private showImg = false
  private bigPictureUrl = ''

  parseGender(g: string) {
    return parseGender(g)
  }

  getFirstAppearTime(person: AnyObj) {
    const time = person.id ? person.firstAppearTime : person.captureTime
    return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '-'
  }

  sceneImageClick(url: string) {
    this.showImg = true
    this.bigPictureUrl = url
  }
}
